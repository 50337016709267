<template>
  <div>
    <a-form layout="inline" :form="form1" @submit="handleSearch">
      <a-form-item>
        <a-input
          v-decorator="['key']"
          placeholder="请输入账号或昵称"
          autocomplete="off"
        >
        </a-input>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" html-type="submit"> 搜索 </a-button>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" html-type="submit"> 刷新 </a-button>
      </a-form-item>
    </a-form>
    <a-table
      :columns="columns"
      :dataSource="data"
      :pagination="false"
      :loading="loading"
      :scroll="{ x: true }"
    >
      <span slot="status" slot-scope="text">
        <span v-if="text === 'complete'">完成</span>
        <span v-else-if="text === 'unfinished'">未完成</span>
      </span>
      <span slot="pay_way" slot-scope="text">
        <span v-if="text === 'alipay'">支付宝</span>
        <span v-else-if="text === 'wechat_pay'">微信</span>
      </span>
    </a-table>
    <Pagination
      :current="current"
      :total="total"
      @handleChange="handleChange"
      v-show="total > 20"
    />
  </div>
</template>

<script>
import { PointRecordModel } from "@/models/pointRecord";
import Pagination from "../../components/Pagination";
import formatTime from "../../utils/formatTime";
import userNameDisplay from "../../utils/userNameDisplay";
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";

const pointRecordModel = new PointRecordModel();
import moment from "moment";
import "moment/locale/zh-cn";

moment.locale("zh-cn");

const columns = [
  {
    title: "购买时间",
    dataIndex: "created_at",
    key: "created_at",
  },
  {
    title: "订单号",
    dataIndex: "order_number",
    key: "order_number",
  },
  {
    title: "订单状态",
    dataIndex: "status",
    key: "status",
    scopedSlots: { customRender: "status" },
  },
  {
    title: "到账积分",
    dataIndex: "quantity",
    key: "quantity",
  },
  {
    title: "支付金额（元）",
    dataIndex: "pay_amount",
    key: "pay_amount",
  },
  {
    title: "支付渠道",
    dataIndex: "pay_way",
    key: "pay_way",
    scopedSlots: { customRender: "pay_way" },
  },
  {
    title: "用户账号",
    dataIndex: "user_name",
    key: "user_name",
  },
  {
    title: "用户昵称",
    dataIndex: "nick_name",
    key: "nick_name",
  },
];
export default {
  data() {
    return {
      form1: this.$form.createForm(this, {
        name: "horizontal_login",
      }),
      data: [],
      columns,
      loading: false,
      total: 0,
      locale,
      current: 1,
    };
  },

  created() {
    this.queryPointPurchasePointRecordList();
  },

  methods: {
    handleSearch(e) {
      e.preventDefault();
      this.form1.validateFields((err, values) => {
        if (!err) {
          let key = values.key ? values.key.trim() : "";
          this.queryPointPurchasePointRecordList(key, "", this.current);
        }
      });
    },

    handleClear() {
      this.form1.resetFields();
      this.queryPointPurchasePointRecordList();
    },

    handleChange(page) {
      this.current = page;
      this.form1.validateFields((err, values) => {
        if (!err) {
          let key = values.key ? values.key.trim() : "";
          this.queryPointPurchasePointRecordList(key, "", this.current);
        }
      });
    },

    queryPointPurchasePointRecordList(key, getWay, page) {
      this.loading = true;
      pointRecordModel.index("purchase", key, getWay, page).then((res) => {
        if (res.code == 0) {
          this.current = page;
          res.result.map((item, index) => {
            item.key = index;
            item.user_name = userNameDisplay(item);
            item.created_at = formatTime(item.created_at);
            return item;
          });
          this.data = res.result;
          this.loading = false;
          this.total = res.total;
        }
      });
    },
  },

  components: {
    Pagination,
  },
};
</script>

<style lang="scss" scoped>
</style>
